<template>
  <v-data-table
    :headers="headers"
    :items="data"
    :loading="loading"
    :footer-props="{
      itemsPerPageOptions: [5, 10, 25, 50, 100],
    }"
    @update:options="loadItems"
  >
    <template v-slot:[`item.act`]="{ item }">
      <v-icon color="primary" @click="viewGarageReports(item)">mdi-eye</v-icon>
    </template>

    <template v-slot:[`item.make`]="{ item }">
      <span
        >{{ item.bidding_report_meta.vehicle.make }}
        {{ item.bidding_report_meta.vehicle.model }}</span
      >
    </template>

    <template v-slot:[`item.date`]="{ item }">
      <span>{{
        (item.checkin_date
          ? item.checkin_date
          : item.bid_template_received_date) | formatToHuman
      }}</span>
    </template>
  </v-data-table>
</template>

<script>
import { formatToHuman } from '@/utils/time'

export default {
  emits: ['set-garage-details', 'options-event'],
  props: {
    garageReports: { type: Array, required: true },
    displayGarageView: { type: Boolean, required: true },
    loading: { type: Boolean, required: true },
  },
  data: () => ({
    selectedItem: null,
  }),
  computed: {
    headers() {
      if (!this.displayGarageView) {
        return [
          {
            text: 'Garage',
            align: 'start',
            sortable: false,
            value: 'garage.name',
          },
          {
            text: 'Garage email',
            align: 'start',
            sortable: false,
            value: 'garage.email',
          },
          {
            text: 'Phone number',
            align: 'start',
            sortable: false,
            value: 'garage.phoneNumber',
          },
          {
            text: 'No. of Vehicles',
            align: 'start',
            sortable: false,
            value: 'count',
          },
          {
            text: 'Action',
            align: 'center',
            sortable: false,
            value: 'act',
          },
        ]
      }
      return [
        { text: 'No.', align: 'start', sortable: false, value: 'booking_id' },
        {
          text: 'Customer',
          align: 'start',
          sortable: false,
          value: 'bidding_report_meta.client.name',
        },
        {
          text: 'Make/ Model',
          align: 'start',
          sortable: false,
          value: 'make',
        },
        {
          text: 'Reg No.',
          align: 'start',
          sortable: false,
          value: 'bidding_report_meta.vehicle.registrationNumber',
        },
        {
          text: 'Date',
          align: 'start',
          sortable: false,
          value: 'date',
        },
        {
          text: 'Phone No.',
          align: 'start',
          sortable: false,
          value: 'bidding_report_meta.client.phoneNumber',
        },
      ]
    },
    data() {
      if (this.displayGarageView) return this.selectedItem.data
      return this.garageReports
    },
  },
  methods: {
    viewGarageReports(item) {
      this.selectedItem = item
      this.$emit('set-garage-details', this.selectedItem.garage.name)
    },
    loadItems(val) {
      this.$emit('options-event', val)
    },
  },
  filters: {
    formatToHuman,
  },
}
</script>
