var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.loading,"footer-props":{
    itemsPerPageOptions: [5, 10, 25, 50, 100],
  }},on:{"update:options":_vm.loadItems},scopedSlots:_vm._u([{key:"item.act",fn:function(ref){
  var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.viewGarageReports(item)}}},[_vm._v("mdi-eye")])]}},{key:"item.make",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.bidding_report_meta.vehicle.make)+" "+_vm._s(item.bidding_report_meta.vehicle.model))])]}},{key:"item.date",fn:function(ref){
  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatToHuman")((item.checkin_date ? item.checkin_date : item.bid_template_received_date))))])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }